@import '../../styles/variables.scss';

.course_requirements {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;

    display: flex;
    flex-direction: column;
    gap: 40px;
}

.course_requirements_title {
    font-family: $Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 120%;
    /* identical to box height, or 58px */


    /* Primary/Default */

    color: #171F30;
}

.course_requirements_body {
    display: flex;
    align-items: center;

    font-family: $Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 58px */


    /* Primary/Default */

    color: #171F30;
}

.course_requirements_footer {
    // display: flex;
    // align-items: center;

    font-family: $Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 58px */


    /* Primary/Default */

    color: #256FFF;
}