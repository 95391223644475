@import '../../styles/variables.scss';

.course_card {
    font-family: $Inter;
    font-weight: 400;
    font-size: 16px;

    width: 578px;
    padding: 40px;

    background-color: white;
    border-radius: 40px;

    // margin: 0 0 40px 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.course_card_header {
    display: flex;
    vertical-align: middle;
    margin-bottom: 60px;
    position: relative;
}

.course_duration {
    padding: 10px 20px;
    color: white;
    border-radius: 100px;
    background-color: $grayScale;
    margin-right: 10px;
}

.course_starting_date {
    padding: 10px 20px;
    color: $grayScale;
    border-radius: 100px;
    border: 1px solid $grayScale;
}

.icon_button {
    position: absolute;
    right: 0;
    background: none;
    border: 0;
    cursor: pointer;
}

.course_card_description_title {
    font-weight: 600;
    color: $grayScale;

    margin-bottom: 20px;
}

.course_card_description_text {
    // color: #889097;
    color: black;
    margin-bottom: 20px;
}

.course_card_description_price {
    font-family: $Inter;
    font-weight: 600;
    color: black;
}

.course_card_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.course_card_footer_dwl_btn {
    padding: 10px 20px;
    border-radius: 100px;
    border: 1px solid $grayScale;
    background-color: rgba(0, 0, 0, 0);
    color: black;
    cursor: pointer;

    &:hover {
        color: white;
        background-color: black;
    }
}