@import '../../styles/variables.scss';


.home {
    background: $light;
    width: 100%;
    display: block;

}

.home_inner {
    width: 1200px;
    display: block;

    margin: 0 auto;

    display: flex;
    justify-content: space-between;

    padding: 40px 0 196px 0;

    position: relative;

    // border: 1px solid red;
}

.title_text_home {
    margin-top: 20px;

    // border: 3px solid blueviolet;

    // width: 750px;
    height: 365px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.first_line_title_text,
.second_line_title_text,
.third_line_title_text {
    font-family: $Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 86px;
    line-height: 120%;
    color: #171F30;

    display: flex;
    justify-content: space-between;

    div {
        display: flex;
        align-items: center;
    }
}

.first_line_title_text {
    div {
        margin-left: 20px;
    }
}

.second_line_title_text {
    .circle_home {
        // border: 1px solid red; 
        position: relative;
        display: flex;
        justify-content: center;
    }

    .small_circle {
        position: absolute;
        z-index: 4;
    }

    .vertical_arrow {
        position: absolute;
        z-index: 3;
        top: 56px;
    }

    .vertical_arrow2 {
        position: absolute;
        z-index: 3;
        top: 84px;
    }

    .vertical_arrow3 {
        position: absolute;
        z-index: 3;
        top: 112px;
    }

    .vertical_arrow4 {
        position: absolute;
        z-index: 3;
        top: 140px;
    }

    .circle_arrow {
        position: absolute;
        z-index: 3;
        top: 168px;
        left: 57px;
    }
}

.third_line_title_text {
    justify-content: space-between;

    img {
        margin-top: 4px;
        margin-left: 80px;
    }
}

.two_buttons {
    margin-top: 60px;

    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.men_img {
    position: absolute;

    bottom: 30px;
    right: -60px;
}