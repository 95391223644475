@import '../../styles/variables.scss';

.experts {
    padding: 0px 0 80px 0;
    font-family: $Inter;
    font-weight: 600;
    color: $grayScale;
    background-color: #F7F9FC;
}

.experts_inner {
    width: 1200px;
    display: block;
    margin: 0 auto;
}

//header===================================

.experts_header {
    display: flex;
    vertical-align: middle;
    margin-bottom: 60px;
    position: relative;
}

.experts_header_title {
    font-size: 48px;
    font-weight: 600;
}

.experts_header_arrows {
    position: absolute;
    right: 0;

    button {
        margin-left: 40px;
        background: none;
        border: 0;
        cursor: pointer;
    }
}

//cards============================

.experts_cards_container_outside {
    position: relative;
}

.experts_cards_container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
}

.experts_cards_container::-webkit-scrollbar {
    display: none;
}

.experts_background_gradient {
    width: 100px;
    height: 100%;
    // border: 2px solid red;
    position: absolute;
    right: 0;

    background: linear-gradient(to left,
            #F7F9FC,
            rgba(#F7F9FC, 0));
}

.expert_card {
    flex: 0 0 auto;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.expert_img {
    margin-bottom: 20px;
    border-radius: 100%;
    height: 80px;
    width: 80px;
}

.expert_name {
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
}

.expert_pro {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #889097;

    span {
        display: block;
    }
}