@import '../../styles/variables.scss';

.info_cta_content {
    background: white;

}

.info_cta_content_inner {
    width: 1200px;
    display: block;
    margin: 0 auto;

    padding-top: 60px;
    padding-bottom: 60px;
}


// header=====================================

.info_cta_header {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    // padding: 60px 0px;

    margin-bottom: 60px;
}

.vl {
    height: 132px;
    width: 2px;
    background-color: $dark;
    display: inline-block;
    border-radius: 10px;
}

.lessons {
    display: inline-block;
    color: $grayScale;
}

.lessons_count {
    font-family: $Inter;
    font-weight: 600;
    font-size: 48px;

    margin-bottom: 20px;
}

.lesson_text {
    font-family: $Inter;
    font-weight: 400;
    font-size: 20px;
}


.first {
    margin-right: 61px;
}

.second {
    margin-right: 64px;
}

.third {
    margin-right: 144px;
}

.fourth {
    margin-right: 100px;
}

.fifth {
    max-width: 300px;
}

//body=================================

.info_cta_body {
    display: flex;
    justify-content: center;
}

.body_container_left {
    width: 100%;
    height: 333px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    background-color: $grayScale;

    position: relative;
    z-index: 2;

    padding-top: 60px;
    padding-left: 100px;
}

.body_container_right img {
    margin-left: -120px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    z-index: 1;
}

.body_container_left_title {
    color: $light;
    font-family: $Inter;
    font-weight: 600;
    font-size: 48px;

}

.body_container_left_form input {
    color: $grayScale;
    padding: 15px;
    width: 414px;
    font-family: $Inter;
    font-weight: 400;
    font-size: 16px;
    border-radius: $borderRadius;
    border: solid white;
    margin: 60px 20px 10px 0px;


}

.body_container_left_subtitle {
    color: $light;

    font-family: $Inter;
    font-weight: 400;
    font-size: 11px;

    opacity: 0.6;

    margin-left: 15px;

    width: 360px;
}

//footer=================================

.info_cta_footer {
    display: flex;
    justify-content: center;

    padding-bottom: 60px
}

.info_cta_footer_left {
    img {
        border-radius: 20px;
    }
}

.info_cta_footer_right {
    margin: auto 55px;
}

.info_cta_footer_right_title {
    font-family: $Inter;
    font-weight: 600;
    font-size: 48px;
    color: $grayScale;

    margin-bottom: 28px;
}

.info_cta_footer_right_subtitles {
    font-family: $Inter;
    font-weight: 400;
    font-size: 16px;
    color: $grayScale;

    display: flex;
    align-items: center;

    margin-top: 40px;
}

.info_cta_footer_right_subtitles img {
    margin-right: 23px;
}

#submit_btn:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: 0.5;
}

.submit_btn_info_cta {
    border: 2px solid $primaryBlue;
    background: none;
    padding: 15px 25px;
    font-size: 16px;
    font-family: $Inter;
    font-weight: 600;
    border-radius: $borderRadius;

    cursor: pointer;

    color: white;
    background-color: $primaryBlue;
}


.submit_btn_info_cta:hover {
    opacity: .5;
}