@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

$Inter: 'Inter', sans-serif;

//colors
$dark: rgb(29, 29, 29);
$light: #fff;
$primary: rgb(162, 162, 246);
$bg: rgb(244, 244, 255);
$primaryBlue: rgb(37, 111, 255);
$grayScale: rgb(23, 31, 48);
$backgroundColor: rgba(247, 249, 252);



//spacing
$spacing-md: 16px;
$spacing-lg: 32px;

//border raduis
$borderRadius: 20px;

//header height
$headerHeight: 100px;

@mixin breakpoint($point) {
    @if $point ==md {
        @media (min-width: 48em) {
            @content
        }
    }
}

$maxWidth: 1920;
$maxWidthContainer: 1240;

@mixin adaptive-value($property, $startSize, $minSize, $type) {
    $addSize: $startSize - $minSize;

    @if $type==1 {
        //Just for less container
        #{$property}: $startSize + px;

        @media (max-width: #{$maxWidthContainer + px}) {
            #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidthContainer - 375}));
        }
    }

    @else if $type==2 {
        //Just for more container
        #{$property}: $startSize + px;

        @media (min-width: #{$maxWidthContainer + px}) {
            #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidth - 375}));
        }
    }

    @else {
        #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidth - 375}));
    }
}