@import '../../styles/variables.scss';
// video===========

.container_video {
    max-width: 1320px;
    margin: 0 auto;
    // padding: 40px 0;
    // display: flex;
    // gap: 20px;
}

.video {
    position: relative;
    // padding-bottom: 35%;
    padding-bottom: 50%;
    height: 0;
    // width: 50%;
    width: 89%;
    margin: 0 auto;

    // @media (min-width: 1240px) {
    //     // padding: 4.35%;
    //     width: 1240px;
    // }

    // border: 1px solid red;
}

.video_frame {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 40px;
    @include adaptive-value("border-radius", 40, 20, 1);

    // @media (min-width: 1921px) {
    //     width: 1176px;

    // }

    // border: 10px solid darkgreen;
}