*,
*::before,
*::after {
  box-sizing: border-box;
}

.app {
  overflow-x: hidden;
  /* background-color: white; */
}
.carousel {
  background-color: #f7f9fc;
  padding-bottom: 50px;
}
