@import '../../styles/variables.scss';

.reviews {
    padding: 40px 0;
    font-family: $Inter;
    font-weight: 600;
    color: $grayScale;
    background-color: #f7f9fc;
}

.review_inner {
    width: 1200px;
    display: block;
    margin: 0 auto;
}

//header===================================
.reviews_header {
    display: flex;
    vertical-align: middle;
    margin-bottom: 40px;
    position: relative;

}

.reviews_header_title {
    font-size: 48px;
    font-weight: 600;

    span {
        color: $primaryBlue;
    }
}

.review_header_arrows {
    position: absolute;
    right: 0;

    button {
        margin-left: 40px;
        background: none;
        border: 0;
        cursor: pointer;
    }
}

//cards============================

.reviews_cards_container_outside {
    position: relative;
}

.reviews_cards_container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
}

.reviews_cards_container::-webkit-scrollbar {
    display: none;
}

.review_background_gradient {
    width: 100px;
    height: 100%;
    // border: 2px solid red;
    position: absolute;
    right: 0;

    background: linear-gradient(to left,
            #F7F9FC,
            rgba(#F7F9FC, 0));
}

.review_card {
    flex: 0 0 auto;
    margin-right: 20px;
    padding: 40px;
    width: 400px;
    background-color: white;
    border-radius: 40px;
}

.review_card_student {
    // border: 1px solid red;
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    div {
        margin-left: 20px;
        display: flexbox;
        align-items: center;

        .name {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 6px;
        }

        .subtitle {
            color: #889097;
            font-size: 16;
            font-weight: 400;
        }
    }
}



.review_card_text {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 40px;
}

.review_rating {
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;

    .rating {
        margin-left: 12px;

        span {
            color: #889097;

        }
    }
}