@import '../../styles/variables.scss';

//footer==========================

.footer {
    width: 100%;
    // height: 156px;
    padding: 60px 0;
    background-color: white;
}

.footer_inner {
    width: 1200px;
    display: block;
    // height: 100%;
    margin: 0 auto;


}

.footer_container {
    // height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    justify-content: space-between;

    .footer_text {
        position: absolute;
        right: 0;

        bottom: 0;
        font-weight: 400;
        font-size: 16px;
        font-family: $Inter;
        color: #889097;
    }
}

.footer_logo {
    img {
        height: 100px;
    }
}

.contact_container {
    font-family: $Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    /* or 24px */

    /* Grayscale/Title-Active */

    color: #171F30;

    display: flex;
    align-items: flex-start;
    flex-direction: column;

    margin-top: 20px;

    gap: 5px;
}

.contact_text,
.number_text {
    font-weight: 700;
    font-size: 40px;
}

.addres_text {
    margin-top: 20px;
    color: $primaryBlue;
}

.footer_insta {
    img {
        height: 50px;
    }
}