@import '../../styles/variables.scss';

.all_courses {
    padding-top: 30px;
    padding-bottom: 80px;
    background: $backgroundColor;
    // width: 100%;
}

.all_courses_inner {
    width: 1200px;
    display: block;
    margin: 0 auto;
    color: black;
}

// title======================================================

.all_courses_title {
    font-family: $Inter;
    font-weight: 600;
    font-size: 48px;
    color: $grayScale;

    margin-bottom: 10px;
}

// courses names =====================================================

.all_courses_names {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    .course_name {
        font-family: $Inter;
        font-weight: 400;
        font-size: 20px;
        color: $primaryBlue;

        border: 1px solid $primaryBlue;
        border-radius: 100px;

        padding: 10px 20px;
        margin: 20px 10px 0 0;

        cursor: pointer;
        background-color: white;
    }

    .course_name.active {
        color: white;
        background-color: $primaryBlue;
        font-weight: 500;
    }

    .course_name:hover {
        opacity: 0.5;
    }
}



// courses cards =====================================================

.course_cards_container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 40px;
}