@import './variables.scss';

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}

body {
    // width: 100%;
    min-width: 1200px;
    font-family: $Inter;
    color: $dark;

    background-color: $backgroundColor;
}

#root {
    height: 100%;
}

.app {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
}