@import './../../styles/variables.scss';

.header {
    width: 100%;
    height: $headerHeight;
    display: block;

    // border: 1px solid $dark;
    background: white;
}

.inner_header {
    width: 1200px;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.logo_container {
    height: 100%;
    float: left;
    display: flex;
    align-items: center;

    img {
        height: 95px;
    }
}

.nav_btn_container {
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
}

.ln_btn {
    margin-right: 30px;

    display: flex;
    gap: 10px;

    .ln_btn_kz,
    .ln_btn_ru {
        font-family: $Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        /* or 19px */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 1px;

        /* Primary/Default */

        color: #256FFF;
        // color: #000000;

        background: rgba($color: #000000, $alpha: 0);
        border: 0;

        cursor: pointer;
    }
}

.header_buttons {
    display: flex;
    gap: 20px;
}