@import '../../styles/variables.scss';

.special-route {
    width: 100%;
    // height: 900px;

    background-color: #808080;

    padding: 80px 0 80px 0;

    margin-bottom: 80px;
    margin-top: 80px;

    // border: 2px solid red;
}

.online_el_inner {
    width: 1200px;
    display: block;
    margin: 0 auto;
}

// header================================

.online_el_header {
    // border: 1px solid red;
    height: 100%;

    margin-bottom: 40px;
}

.online_el_subheader {
    color: $light;

    font-family: $Inter;
    font-weight: 600;
    font-size: 18px;

    margin-bottom: 40px;
}

.online_el_header_title {
    color: $light;

    font-family: $Inter;
    font-weight: 600;
    font-size: 48px;
}

.online_el_header_arrows {
    float: right;

    margin-top: 4px;

    img {
        margin-left: 40px;
        cursor: pointer;
    }

    img:hover {
        opacity: 0.5;
    }
}

//card container==============================

.online_el_cards_container_outside {
    position: relative;

}

.online_el_cards_container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    // padding-right: -20px;
}

.online_el_cards_container::-webkit-scrollbar {
    // color: blue;
    // background: aliceblue;
    display: none;
}

.background_gradient {
    width: 300px;
    height: 100%;
    // border: 2px solid red;
    position: absolute;
    right: 0;

    background: linear-gradient(to left,
            #171F30,
            rgba(#171F30, 0));
}

.online_el_card {
    width: 350px;
    height: 300px;
    background-color: lightgreen;
    border-radius: 40px;
    padding: 20px;

    margin-right: 40px;

    flex: 0 0 auto;
}

.card_number {
    width: 60px;
    height: 60px;
    background-color: $grayScale;
    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: $light;

        font-family: $Inter;
        font-weight: 600;
        font-size: 32px;
    }

    margin-bottom: 66px;
}

.card_name {
    color: $grayScale;

    font-family: $Inter;
    font-weight: 600;
    font-size: 32px;

    width: 380px;

}