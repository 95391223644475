@import '../../../styles/variables.scss';

// .nav_btn_container{
//     height: 100%;
//     float: right;
//     display: flex;
//     align-items: center;
// }

.btn {
    border: 2px solid $primaryBlue;
    background: none;
    padding: 15px 25px;
    font-size: 16px;
    font-family: $Inter;
    font-weight: 600;
    border-radius: $borderRadius;

    cursor: pointer;

    color: white;
    background-color: $primaryBlue;
}

.btn.white {
    color: $primaryBlue;
    background-color: rgba($color: #000000, $alpha: 0);
}

button:hover {

    opacity: .5;
}